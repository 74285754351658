import Image1 from '../../assets/image/saasModern/p_1.png';
import Image2 from '../../assets/image/saasModern/p_2.png';
import Image3 from '../../assets/image/saasModern/p_3.png';
import Screenshot1 from '../../assets/image/saasModern/t_4.png';
import Screenshot2 from '../../assets/image/saasModern/t_1.png';
import Screenshot3 from '../../assets/image/saasModern/t_2.png';
import Screenshot4 from '../../assets/image/saasModern/t_3.png';

import AuthorOne from '../../assets/image/saasModern/dash-3.png';
import AuthorTwo from '../../assets/image/saasModern/dash-3.png';
import AuthorThree from '../../assets/image/saasModern/dash-3.png';

import { ic_monetization_on } from 'react-icons-kit/md/ic_monetization_on';
import { ic_settings } from 'react-icons-kit/md/ic_settings';
import { pieChart } from 'react-icons-kit/icomoon/pieChart';
import { briefcase } from 'react-icons-kit/fa/briefcase';

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Feature',
    path: '#feature_section',
    offset: '0',
  },
  {
    label: 'Pricing',
    path: '#pricing_section',
    offset: '0',
  },
  {
    label: 'Testimonial',
    path: '#testimonial_section',
    offset: '0',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '0',
  },
];

export const PROCESS_ITEMS = [
  {
    image: Image2,
    title: 'Ordina da mangiare',
    description:
      'I tuoi clienti potranno ordinare da mangiare direttamente dalla tua app.',
  },
  {
    image: Image1 ,
    title: 'Status del Ordine',
    description:
      'Potrai tenerli aggiornati con lo stato del ordine.',
  },
  {
    image: Image3,
    title: 'Codici Sconto',
    description:
      'Potrai creare codici sconto per fidelizzare i tuoi clienti.',
  }
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For Small teams or group who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Business Account',
    description: 'For Mediums teams or group who need to build website ',
    price: '$9.87',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$12.98',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For a single client or team who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Business Account',
    description: 'For Small teams or group who need to build website ',
    price: '$6.00',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Unlimited secure storage',
      },
      {
        content: '2,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: '24/7 phone support',
      },
      {
        content: '50+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$9.99',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '3,000s of Templates Ready',
      },
      {
        content: 'Advanced branding',
      },
      {
        content: 'Knowledge base support',
      },
      {
        content: '80+ Webmaster Tools',
      },
    ],
  },
];

export const FAQ_DATA = [
  {
    expend: true,
    title: 'How to contact with Customer Service?',
    description:
      'Our Customer Experience Team is available 7 days a week and we offer 2 ways to get in contact.Email and Chat . We try to reply quickly, so you need not to wait too long for a response!. ',
  },
  {
    title: 'App installation failed, how to update system information?',
    description:
      'Please read the documentation carefully . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum . ',
  },
  {
    title: 'Website reponse taking time, how to improve?',
    description:
      'At first, Please check your internet connection . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum .',
  },
  {
    title: 'New update fixed all bug and issues?',
    description:
      'We are giving the update of this theme continuously . You will receive an email Notification when we push an update. Always try to be updated with us .',
  },
];

export const FOOTER_WIDGET = [
  {
    title: 'About Us',
    menuItems: [
      {
        url: '#',
        text: 'Support Center',
      },
      {
        url: '#',
        text: 'Customer Support',
      },
      {
        url: '#',
        text: 'About Us',
      },
      {
        url: '#',
        text: 'Copyright',
      },
      {
        url: '#',
        text: 'Popular Campaign',
      },
    ],
  },
  {
    title: 'Our Information',
    menuItems: [
      {
        url: '#',
        text: 'Return Policy',
      },
      {
        url: '#',
        text: 'Privacy Policy',
      },
      {
        url: '#',
        text: 'Terms & Conditions',
      },
      {
        url: '#',
        text: 'Site Map',
      },
      {
        url: '#',
        text: 'Store Hours',
      },
    ],
  },
  {
    title: 'My Account',
    menuItems: [
      {
        url: '#',
        text: 'Press inquiries',
      },
      {
        url: '#',
        text: 'Social media directories',
      },
      {
        url: '#',
        text: 'Images & B-roll',
      },
      {
        url: '#',
        text: 'Permissions',
      },
      {
        url: '#',
        text: 'Speaker requests',
      },
    ],
  },
  {
    title: 'Policy',
    menuItems: [
      {
        url: '#',
        text: 'Application security',
      },
      {
        url: '#',
        text: 'Software principles',
      },
      {
        url: '#',
        text: 'Unwanted software policy',
      },
      {
        url: '#',
        text: 'Responsible supply chain',
      },
    ],
  },
];

export const FEATURES = [
  {
    "icon": "../../assets/image/saasModern/s_1.svg",
    "title": "ORDINAZIONE TAKEAWAY O A DOMICILIO",
    "description": "Offri la possibilità ai tuoi clienti di prenotare direttamente dalla tua app i loro ordini, scegliendo il metodo di pagamento e l’orario di consegna."
  },
  {
    "icon": "../../assets/image/saasModern/s_2.svg",
    "title": "GESTIRE E RICEVERE SCONTI E PROMOZIONI",
    "description": "I tuoi clienti potranno usufruire degli sconti e promozioni che penserai per loro, tramite appositi codici sconto inseribili su sito web, brochures, volantini."
  },
  {
    "icon": "../../assets/image/saasModern/s_5.svg",
    "title": "PRENOTARE UN TAVOLO",
    "description": "L’app permette di inserire la funzione di prenotazione tavolo, selezionando la fascia oraria desiderata in base alla disponibilità del ristorante, gestibile tramite pannello di controllo da parte del ristoratore."
  },
  {
    "icon": "../../assets/image/saasModern/s_3.svg",
    "title": "CUSTOMIZZA LA TUA APP",
    "description": "Potrai scegliere il tuo layout, il logo, la grafica che renderà unica la tua app: il contorno ideale per le funzionalità che avrai deciso di mettere\na disposizione per i tuoi clienti."
  },
  {
    "icon": "../../assets/image/saasModern/s_4.svg",
    "title": "SCEGLI IL TIPO DI ABBONAMENTO",
    "description": "Scegli la tua combinazione di funzionalità\nin base alle esigenze dei clienti e le necessità\ndel tuo business: il prezzo è flessibile per venire incontro alla tua scelta."
  },
  {
    "icon": "../../assets/image/saasModern/s_6.svg",
    "title": "GESTISCI I SERVIZI DELL’APP DA REMOTO",
    "description": "Puoi gestire l’app in completa autonomia tramite il pannello di controllo dedicato a tua disposizione: gli ordini degli utenti e la loro accettazione, la disponibilità dei tavoli, le zone di consegna, la creazione del menù."
  }
]

export const SCREENSHOTS = [
  {
    icon: ic_settings,
    title: 'Menù',
    image: Screenshot2,
  },
  {
    icon: ic_monetization_on,
    title: 'Ordini',
    image: Screenshot3,
  },
  {
    icon: pieChart,
    title: 'Categorie del menu',
    image: Screenshot1 ,
  },
  {
    icon: briefcase,
    title: 'Codici Sconto',
    image: Screenshot4,
  },
];

export const TESTIMONIALS = [
  {
    title: 'Modern look & trending design',
    review:
      'Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
    name: 'Jon Doe',
    designation: 'CEO of Dell Co.',
    avatar: `${AuthorOne}`,
  },
  {
    title: 'Modern look & trending design',
    review:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features Lorem ipsum dolor sit amet consectetur.',
    name: 'Jon Doe',
    designation: 'Co Founder of IBM',
    avatar: `${AuthorTwo}`,
  },
  {
    title: 'Modern look & trending design',
    review:
      'Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
    name: 'Jeny Doe',
    designation: 'Manager of Hp co.',
    avatar: `${AuthorThree}`,
  },
];
