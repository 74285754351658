import styled from 'styled-components';
import BannerBG from 'common/assets/image/restaurant.jpg';

export const BannerWrapper = styled.section`
  padding: 150px 0 50px 0;
  background-image: url(${BannerBG}),
    linear-gradient(35deg, rgb(184, 58, 75) 0%, rgb(184, 58, 75) 100%);
  background-size: cover;
  background-position: top center;
  @media (max-width: 575px) {
    padding: 120px 0 0 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  

`;

export const TextWrapper = styled.text`
  color: '#fff';
  font-family: 'Open Sans', sans-serif;
  background-image: linear-gradient(120deg, #B83A4B 0%, #B83A4B 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.25em;
  font-size: 48px;
  background-position: 0 88%;
  font-weight: '700';
`;

export const DiscountWrapper = styled.div`
  text-align: center;
`;

export const DiscountLabel = styled.div`
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  border-radius: 4em;
  padding: 9px 22px;
  margin-bottom: 22px;
  background-color: rgba(255, 255, 255, 0.15);
  @media (max-width: 575px) {
    padding: 7px 10px;
  }
`;
